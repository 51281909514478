module.exports = {
    "web-developer": {
        "pt": "Desenvolvedor Web.",
        "en": "Web Developer."
    },
    "frontend-developer": {
        "pt": "Desenvolvedor Frontend.",
        "en": "Frontend Developer."
    },
    "working-together": {
        "pt": "Vamos trabalhar juntos!",
        "en": "Let's work together!"
    },
    "project": {
        "pt": "Projetos",
        "en": "Work"
    },
    "contact": {
        "pt": "Contato",
        "en": "Contact"
    },
    "Pro-jects": {
        "pt": "Pro-",
        "en": "Pro-"
    },
    "pro-Jects": {
        "pt": "jetos",
        "en": "jects"
    },
    "system-sereia-urbana-title": {
        "pt": "/Sistema<br> Sereia Urbana",
        "en": "/Sereia<br> Urbana System"
    },
    "system-sereia-urbana-text": {
        "pt": "Sistema desenvolvido para a estética Sereia Urbana, controlando desde a entrada do paciente até o controle de caixa. No back-end foi usado Go e no front-end react, a infraestrutura pela Digital Ocean. Com uma mescla perfeita tendo uma ótima performance e um custo muito baixo de manutenção mensal.",
        "en": "System developed for the Urban Sereia Urbanada, controlling from patient entrance until cashier control. In the back end was used GO and in the front end ReacJS, the infrastructure by Digital Ocean. With a perfect mix having great performance and a very low monthly maintenance cost."
    },
    "website-fioscar-title": {
        "pt": "/Website<br> Fioscar",
        "en": "/Fioscar<br> Website"
    },
    "website-fioscar-text": {
        "pt": "Website desenvolvido para um centro automotivo, procurando equilibrar uma performance e tecnologias atuais. Foi construido em vueJS, unindo o SPA com um sistema para melhor indexação dos buscadores.",
        "en": "Website developed for an automotive center, seeking to balance current performance and technologies. It was built in vueJS, joining the SPA with a system to improve the search engine indexing system."
    },
    "website-sereia-urbana-title": {
        "pt": "/Website <br>Sereia Urbana",
        "en": "/Website <br>Sereia Urbana"
    },
    "website-sereia-urbana-text": {
        "pt": "Por pedido do cliente, foi mantido a mesma linha desenvolvimento no website. Um SPA que saísse da linha de um website normal, com um design inovador e demonstrasse o que a empresa tem para oferecer. Foi construído em vueJS, pois atendia aos requisitos e cumpriu o papel de ser um ótimo percursor no desenvolvimento de websites.",
        "en": "By customer request, the same development line was maintained on the website. A SPA that goes beyond the line of a normal website with an innovative design and demonstrates what the company has to offer. It was built in vueJS as it met the requirements and fulfilled the role of being a great precursor in website development."
    },
    "system-clients-title": {
        "pt": "/Sistema<br> de Clientes",
        "en": "/Customer<br> system"
    },
    "system-clients-text": {
        "pt": "Sistema desenvolvido para um controle interno de clientes. O que antes era jogado em cards do trello, foi reoganizado em um sistema. Nele foi utilizado Angular7 e para a base de dados Firebase, se tornando um aplicativo muito performático e simples, cumprindo o seu papel.",
        "en": "Customer System. Before was managable by trello in cards and now it is organized in an app. In this project was used Angular7 and for database Firebase. Becoming an app performatic and simple fulfilling the pourpose"
    },
    "need-a-help": {
        "pt": "Precisa de ajuda?",
        "en": "Need some help?"
    },
    "send-me-a-message": {
        "pt": "Me envie uma mensagem",
        "en": "Send me a message"
    },
    "whats-your-name": {
        "pt": "Qual é seu nome?",
        "en": "What is your name?"
    },
    "your-email": {
        "pt": "Seu email",
        "en": "Your name"
    },
    "your-phone": {
        "pt": "Seu telefone",
        "en": "Your phone"
    },
    "type-your-message": {
        "pt": "Digite sua mensagem",
        "en": "Type your message"
    },
    "send": {
        "pt": "Enviar",
        "en": "Send"
    },
    "sent-success": {
        "pt": "Enviado com sucesso! Responderei o mais rápido possível.",
        "en": "Successfully sent! I'll answer as soon as possible."
    },
    "fill-all-inputs": {
        "pt": "Por gentileza, preencha todos os campos.",
        "en": "Please fill all the inputs."
    }
};