import '../style/style.scss';
import './cursor';

// const baseUrl = 'http://localhost/privat/portfolio/';
const baseUrl = 'https://scheidemantel.com.br';
const version = '1.0.0';

if (!localStorage.getItem('lang?v=' + version)) localStorage.setItem('lang?v=' + version, 'pt');
var lang = localStorage.getItem('lang?v=' + version);

if (!localStorage.getItem('translations?v=' + version)) {
    var translationJson = require('./translations.json');
    translationJson = JSON.stringify(translationJson);
    localStorage.setItem('translations?v=' + version, translationJson);
}
var translationByStorage = JSON.parse(localStorage.getItem('translations?v=' + version));

let body = document.querySelector('body');
let homeDiv = window.scrollY + document.querySelector('#home').getBoundingClientRect().top;
let workDiv = window.scrollY + document.querySelector('#work').getBoundingClientRect().top;
let contactDiv = window.scrollY + document.querySelector('#contact').getBoundingClientRect().top + 50;

let menuBtn = document.querySelectorAll('.js-link-menu');
for (let i = 0; i < menuBtn.length; i++) {
    const element = menuBtn[i];
    element.addEventListener('click', function () {
        for (let j = 0; j < menuBtn.length; j++) menuBtn[j].classList.remove('selected');
        this.classList.add('selected');
        // handleScrollMenu(this.dataset.menu);
    });
}

// function handleScrollMenu(type) {
//     let nameRoute;

//     if (type == 'home') {
//         body.scrollTo({ top: homeDiv, behavior: 'smooth' });

//         nameRoute = '/';
//         window.history.pushState(null, null, nameRoute);
//     }
//     if (type == 'work') {
//         body.scrollTo({ top: workDiv, behavior: 'smooth' });

//         nameRoute = '/work';
//         if (lang == 'pt') nameRoute = '/projetos';

//         window.history.pushState(null, null, nameRoute);
//     }
//     if (type == 'contact') {
//         body.scrollTo({ top: contactDiv, behavior: 'smooth' });

//         nameRoute = '/contact';
//         if (lang == 'pt') nameRoute = '/contato';

//         window.history.pushState(null, null, nameRoute);
//     }
// }
body.addEventListener('scroll', function (e) {
    if (body.scrollTop >= homeDiv) {
        handleSelectMenu(true);
    }
    if (body.scrollTop >= workDiv) {
        handleSelectMenu(false, 1, 2);
    }
    if (body.scrollTop >= (contactDiv - 150)) {
        handleSelectMenu(false, 2, 1);

    }
});
//coloca a marcacao de selecionado no menu
function handleSelectMenu(type = false, add, remove) {
    if (type) {
        menuBtn[1].classList.remove('selected');
        menuBtn[2].classList.remove('selected');
    } else {
        menuBtn[add].classList.add('selected');
        menuBtn[remove].classList.remove('selected');
    }
}
//vai lidar com o menu que vier na url, deixado selecionado e dando um scroll ate o mesmo
function handleParamUrl() {
    var route = window.location.href;
    route = route.split("/")[3];
    if (route == 'trabalho') route = 'work';
    if (route == 'projeto') route = 'work';
    if (route == 'contato') route = 'contact';
    if (route == 'home') {
        // body.scrollTo({ top: homeDiv, behavior: 'smooth' });
        handleSelectMenu(true);
    }
    if (route == 'work') {
        // body.scrollTo({ top: workDiv, behavior: 'smooth' });
        handleSelectMenu(false, 1, 2);
    }
    if (route == 'contact') {
        // body.scrollTo({ top: contactDiv, behavior: 'smooth' });
        handleSelectMenu(false, 2, 1);
    }
}

function selectLang(firstRound = false) {
    var btnLangs = document.querySelectorAll('.js-lang-selection');
    if (firstRound) {
        if (lang == 'pt') {
            handleClassSelected(btnLangs[0], btnLangs[1]);
            translate(true);
        }
        if (lang == 'en') {
            handleClassSelected(btnLangs[1], btnLangs[0]);
            translate(true);
        }
    }
    btnLangs[0].addEventListener('click', () => {
        if (lang != 'pt') {
            handleClassSelected(btnLangs[0], btnLangs[1]);
            localStorage.setItem('lang?v=' + version, 'pt');
            translate();
        }
    });
    btnLangs[1].addEventListener('click', () => {
        if (lang != 'en') {
            handleClassSelected(btnLangs[1], btnLangs[0]);
            localStorage.setItem('lang?v=' + version, 'en');
            translate();
        }
    });

}

function handleClassSelected(p1, p2) {
    p1.classList.add('selected');
    p2.classList.remove('selected');
}

function translate(firstRound = false) {

    let toTranslateItems = document.querySelectorAll('[data-translate]');
    if (localStorage.getItem('lang?v=' + version) == 'pt') {
        for (let i = 0; i < toTranslateItems.length; i++) {
            const element = toTranslateItems[i];
            if (!firstRound) {
                element.classList.add('translationFadeOut');
                setTimeout(() => {
                    element.classList.remove('translationFadeOut');
                    handlePlaceholderTranslate(element, 'pt');
                    element.classList.add('translationFadeIn');
                    setTimeout(() => {
                        element.classList.remove('translationFadeIn');
                    }, 500);
                }, 500);
            } else {
                handlePlaceholderTranslate(element, 'pt');
            }
            lang = 'pt';
        }
    }
    if (localStorage.getItem('lang?v=' + version) != 'pt') {
        for (let i = 0; i < toTranslateItems.length; i++) {
            const element = toTranslateItems[i];
            if (!firstRound) {
                element.classList.add('translationFadeOut');
                setTimeout(() => {
                    element.classList.remove('translationFadeOut');
                    handlePlaceholderTranslate(element, 'en');
                    element.classList.add('translationFadeIn');
                    setTimeout(() => {
                        element.classList.remove('translationFadeIn');
                    }, 500);
                }, 500);
            } else {
                handlePlaceholderTranslate(element, 'en');
            }
            lang = 'en';
        }
    }
}
function handlePlaceholderTranslate(el, langSel) {
    if (el.dataset.translate.length > 0) {
        if (el.dataset.translate.includes('placeholder-')) {
            let realTranslation = el.dataset.translate.substring(12);
            el.placeholder = translationByStorage[realTranslation][langSel];
        } else {
            el.innerHTML = translationByStorage[el.dataset.translate][langSel];
        }
    }
}

let btnPlay = document.querySelectorAll('.js-play');
let divToSetVideo = document.querySelectorAll('.js-grid__image');
let videos = document.getElementsByTagName('video');



for (let i = 0; i < btnPlay.length; i++) {
    const element = btnPlay[i];
    element.addEventListener('click', function () {
        if (videos[0]) videos[0].remove();
        if (videos[1]) videos[1].remove();
        if (videos[2]) videos[2].remove();
        let videoSrc = this.dataset.video;
        let video = document.createElement('video');
        video.id = 'js-running-video';
        video.src = `${baseUrl}app/images/projects/video-${videoSrc}.mp4`;
        video.autoplay = true;
        divToSetVideo[i].appendChild(video);
        if (document.documentElement.clientWidth <= 950) {
            let runningVideo = document.querySelector('#js-running-video');
            runningVideo.scrollIntoView({ block: "start", behavior: "smooth" });
        }
        video.addEventListener('ended', () => {
            video.remove();
        }, false);
    });
}

let loadingMessage = document.querySelector('.animation__loading');
let btnSendMessage = document.querySelector('.js-send-email');

//inputs
let nameIn = document.querySelector('#js-input-name');
let emailIn = document.querySelector('#js-input-email');
let phoneIn = document.querySelector('#js-input-phone');
let messageIn = document.querySelector('#js-input-message');

let allInputs = document.querySelectorAll('.js-input');

for (let i = 0; i < allInputs.length; i++) {
    const elInput = allInputs[i];
    elInput.addEventListener('input', function () {
        if (this.getAttribute('id') == 'js-input-email') {

        }
        if (nameIn.value && (emailIn.value || phoneIn.value) && messageIn.value) {
            handleWarningsForm(false, 0);
        }
    });
}

btnSendMessage.addEventListener('mouseenter', (e) => {
    if (!nameIn.value || (!emailIn.value && !phoneIn.value) || !messageIn.value) {
        handleWarningsForm(true, 1);
    } else {
        handleWarningsForm(false, 0);
    }
});
function handleWarningsForm(disable, opacity) {
    if (disable) {
        btnSendMessage.setAttribute("disabled", 'disabled');
    } else {
        btnSendMessage.setAttribute("disabled", "");
    }
    document.querySelector('.js-fill__all').style.opacity = opacity;
}

btnSendMessage.addEventListener('click', (e) => {
    if (!nameIn.value || (!emailIn.value && !phoneIn.value) || !messageIn.value) {
        document.querySelector('.js-fill__all').style.display = 'block';
        return;
    }
    e.target.style.opacity = 0;
    e.target.style.height = 0;
    e.target.style.padding = 0;
    loadingMessage.style.display = 'block';

    fetch(`${baseUrl}/lib/mail/send.php`, {
        method: 'post',
        body: JSON.stringify({
            name: nameIn.value,
            email: emailIn.value,
            phone: phoneIn.value,
            message: messageIn.value
        })
    }).then(res => {
        return res.text();
    })
        .then(data => {
            loadingMessage.style.display = 'none';
            if (data == 'true') {
                document.querySelector('.js-sent__successfully').style.display = 'block';
            } else {
                e.target.style.opacity = 1;
                e.target.style.height = 'unset';
                e.target.style.padding = '1.9rem 3.4rem';
            }

        });

});
selectLang(true);
translate(true);
// handleParamUrl();